// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAObUyszpookjd-7_p1h_uAFMsmzVXovEw",
  authDomain: "yvom2nd-a903f.firebaseapp.com",
  databaseURL: "https://yvom2nd-a903f-default-rtdb.firebaseio.com",
  projectId: "yvom2nd-a903f",
  storageBucket: "yvom2nd-a903f.appspot.com",
  messagingSenderId: "654303175678",
  appId: "1:654303175678:web:097e5aad4709e8449fd6af",
  measurementId: "G-9Z6P4WGWVE"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };